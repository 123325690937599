/** @format */

body {
  margin: 0;
  background: #ededed;
  font-family: "Montserrat", sans-serif;
}

em {
  font-style: normal;
}

a {
  text-decoration: none;
  color: black;
}

.zsiq_flt_rel {
  display: none !important;
}

.cmplogo {
  display: none !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  opacity: 0.5 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  width: 140px;
  border: none;
  border-width: 0px !important;
  border-style: none !important;
  margin-top: -15px;
  padding-right: 0px !important;
  border-radius: 0px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 2px !important;
}

.datePicker
  > .MuiOutlinedInput-notchedOutline
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-bottom: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
